<template>
  <div v-if="leads" class="main">
    <div class="tabs">
      <div :class="{selected: selectPP == 'trump'}" @click="selectPP = 'trump'">Trump</div>
      <div :class="{selected: selectPP == 'moonstart'}" @click="selectPP = 'moonstart'">Moonstar</div>
      <div :class="{selected: selectPP == 'affiliate'}" @click="selectPP = 'affiliate'">Affiliate</div>
      <div :class="{selected: selectPP == 'lion'}" @click="selectPP = 'lion'">Lion</div>
    </div>
    <div class="main_search">
      <label class="label">
        Поиск
        <input v-model="searchEmail" type="text" placeholder="Email" />
      </label>
    </div>
    <table>
      <thead>
        <tr>
          <th>#</th>
          <th>countryName</th>
          <th @click="sortedDate = !sortedDate;">signupDate</th>
          <th>email</th>
          <th>firstName</th>
          <th>lastName</th>
          <th>saleStatus</th>
          <th>hasFTD</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!leadInPP.length">
          <td style="text-align: center;" colspan="7">Лидов нет</td>
        </tr>
        <tr v-else v-for="(elem, index, count) in leadInPP">
          <td>{{ index + 1 }}</td>
          <td>{{ elem.countryName }}</td>
          <td>{{ elem.signupDate }}</td>
          <td>{{ elem.email }}</td>
          <td>{{ elem.firstName }}</td>
          <td>{{ elem.lastName }}</td>
          <td>{{ elem.saleStatus }}</td>
          <td>{{ elem.hasFTD }}</td>
        </tr>
      </tbody>

    </table>

  </div>
  <div v-else class="main">
    <h2>Лидов нет(</h2>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      api: "/api.php?getLeads", // Запрос на лиды к файлу PHP
      leads: null, // Лиды из бд
      sortedDate: true,
      searchEmail: '',
      selectPP: 'trump',
    }
  },
  components: {
  },
  mounted() {
    this.getLeads();
  },
  computed: {
    leadsSorted() {

      if (!this.leads) return [];

      if (!this.sortedDate) {
        return this.leads.sort((a, b) => new Date(b.signupDate) - new Date(a.signupDate)).reverse();
      } else {
        return this.leads.sort((a, b) => new Date(b.signupDate) - new Date(a.signupDate));
      }
    },
    leadSearch() {
      if (this.leadsSorted) {
        return this.leadsSorted.filter(lead => lead.email.includes(this.searchEmail));
      } else {
        return [];
      }
    },
    leadInPP() {
      if (this.leadSearch) {
        return this.leadSearch.filter(lead => lead.pp == this.selectPP);
      } else {
        return [];
      }
    }
  },
  methods: {
    async getLeads() {
      try {
        const response = await axios.post(
          this.api,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )

        if (response.data && response.data) {
          this.leads = response.data;
        } else {
          this.leads = null;
        }


      } catch (e) {
        console.log(e);
        return false;
      }
    },
  }
}
</script>
<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Segoe UI', Segoe, Tahoma, Geneva, sans-serif;
}

#app {
  padding: 10px;
  max-width: 1100px;
  margin: 0 auto;
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main {
  overflow: auto;
  width: 100%;
}

.label {
  display: flex;
  flex-direction: column;
}

.label input {
  padding: 3px;
  font-size: 15px;
  border: none;
  background: #e1e1e1a3;
  border-radius: 5px;
}

table {
  white-space: nowrap;
    margin-top: 20px;
    overflow: auto;
    height: 70vh;
    display: block;
}

thead {
  background-color: #ececec;
  border-radius: 5px;
}

thead th {
  padding: 5px;
  width: 100%;
}


tbody td {
  padding: 0 10px;
}

.tabs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.tabs div {
  padding: 5px 10px;
    background: #ececec;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
    border: 2px solid transparent;
    transition: all .3s;
}

.tabs div:hover {
  opacity: .5;
}

.tabs div.selected {
  border: 2px solid #00b8ff63;
}
</style>
